export default {
  name: "Video",

  data() {
    return {
      isPlaying: false
    };
  },

  watch: {
    isPlaying(value) {
      if (this.$refs.video) {
        if (value) {
          this.$refs.video.play();
        } else {
          this.$refs.video.pause();        
        }
      }
    }
  },
};
