export default {
  name: "Hero",

  data() {
    return {
      isPlaying: false,
      mediaType: null
    };
  },

  watch: {
    isPlaying(value) {
      if (this.$refs.video) {
        if (value) {
          this.$refs.video.play();
          if (window.innerWidth < 960) {
            this.$refs.video.requestFullscreen();
          }
        } else {
          this.$refs.video.pause();        
        }
      }
    }
  },

  mounted() {
    setTimeout(this.setup, 10);
  },

  methods: {
    setup() {
      if (!!this.$refs.media.querySelector("video")) {
        this.mediaType = "video";
      } else if (!!this.$refs.media.querySelector("img")) {
        this.mediaType = "image";
      }
    },

    scrollToNext() {
      const topHeight = document.querySelector(".top").offsetHeight;
      const top = window.pageYOffset + this.$el.nextElementSibling .getBoundingClientRect().top - topHeight;
      
      window.scrollTo({
        top,
        behavior: "smooth"
      });
    }
  }
};
