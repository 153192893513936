import ContactForm from "./contactform.js";
import Gallery from "./gallery.js";
import Hero from "./hero.js";
import LangSwitch from "./langswitch.js";
import Top from "./top.js";
import Video from "./video.js";

export default {
  ContactForm,
  Gallery,
  Hero,
  LangSwitch,
  Top,
  Video
};
