// scss
import "../scss/index.scss";

// js
import Vue from "vue";
import * as components from "./base/components.js";

Promise.resolve() 
  .then(() => {
    if (["interactive", "complete"].includes(document.readyState)) {
      return Promise.resolve();
    } else {
      return new Promise(resolve => {
        const onReadyStateChange = function (event) {
          if (["interactive", "complete"].includes(event.target.readyState)) {
            document.removeEventListener("readystatechange", onReadyStateChange);
            resolve();
          }
        };
        document.addEventListener("readystatechange", onReadyStateChange);
      });
    }
  })
  .then(() => {
    components.init();
  });

window.$emitter = new Vue();
