export default {
  name: "LangSwitch",

  data() {
    return {
      active: false
    };
  },

  methods: {
    toggle() {
      this.active = !this.active;
    }
  }
};
