export default {
  name: "ContactForm",

  data() {
    return {
      marketingChecked: false,
      privacyChecked: false
    };
  },

  mounted() {
    if (this.$refs.form) {
      this.$refs.form.addEventListener('submit', (e) => {
        e.preventDefault()
        grecaptcha.ready(() => {
          grecaptcha.execute('6Lc6kZ0iAAAAAI8ouVQTUAC_LmpxV-T_t07Nm5Ak', {action: 'submit'}).then((token) => {
            this.$refs.token.value = token
            this.$refs.form.submit()
          });
        })
      })
    }
  },

  methods: {
    toggleMarketing() {
      this.marketingChecked = !this.marketingChecked;
    },

    togglePrivacy() {
      this.privacyChecked = !this.privacyChecked;
    }
  }
};
