import _ from "lodash";
import Vue from "vue";
import componentsList from "../components/index.js";
import store from "./store.js";

export function init() {
  parse();
}

export function parse() {
  const componentsEls = Array.from(document.querySelectorAll('[data-component]'));
  componentsEls.forEach(function (el) {
    const componentName = el.getAttribute("data-component");
    const componentId = el.getAttribute("data-component-id");
    if (!componentsList[componentName]) {
      return;
    }
    const componentObject = _.cloneDeep(componentsList[componentName]);
    if (_.isFunction(componentObject.data)) {
      componentObject.data = componentObject.data();
    }
    if (!_.isPlainObject(componentObject.data)) {
      componentObject.data = {};
    }

    // decorate componentObject
    componentObject.el = el;
    componentObject.store = store;
    if (_.isPlainObject(window[`__${componentId}`])) {
      componentObject.data = Object.assign(componentObject.data, window[`__${componentId}`]);
      const script = document.querySelector(`script[data-component-id="${componentId}"]`);
      if (script) {
        script.parentNode.removeChild(script);
      }
    }
    componentObject.data.id = componentId;
    
    // remove el attributes
    el.removeAttribute("data-component");
    el.removeAttribute("data-component-id");

    // create component instance
    const componentInstance = new Vue(componentObject);
  });
}
