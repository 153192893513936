export default {
  name: "Top",

  data() {
    return {
      activeLang: false,
      navActive: false,
      scroll: false
    };
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      this.$options.intListener = new IntersectionObserver(entries => {
        this.scroll = !entries[0].isIntersecting;
      });
      this.$options.intListener.observe(document.querySelector(".top__topline")); // outside the component
    },

    toggleLang() {
      this.activeLang = !this.activeLang;
    },

    toggleNav() {
      this.navActive = !this.navActive;
    }
  }
};
