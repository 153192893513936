export default {
  name: "Gallery",

  data() {
    return {
      images: []
    };
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      Array.from(this.$el.querySelectorAll("img")).forEach(el => {
        this.images.push({
          src: el.getAttribute("data-image-url"),
          w: parseInt(el.getAttribute("data-image-width")),
          h: parseInt(el.getAttribute("data-image-height"))
        });
      });
    },

    toggleLightbox(e) {
      const index = Array.from(this.$el.querySelectorAll("img")).indexOf(e.target);
      const gallery = new PhotoSwipe(document.querySelector(".pswp"), PhotoSwipeUI_Default, this.images, {
        index,
        history: false
      });
      gallery.init();
    }
  }
};
